import { useSsoRedirectStore } from '~/stores/ssoRedirectStore'

export default defineNuxtRouteMiddleware(async (ctx) => {
  const result = await useAuthenticate()

  const ssoRedirectStore = useSsoRedirectStore()
  // HACK: read the redirect url from the store, if not present, redirect to student hub
  const redirect = ssoRedirectStore.pop() || '/student-hub'
  ssoRedirectStore.push(ctx.fullPath)
  if (result?.isAuthenticated.value) {
    return true
  }

  return navigateTo(redirect)
})
